import React, {useState, useEffect} from "react";
import {Fab, IconButton,  } from "@mui/material";
import {classList, debounce} from "../utils";
import ScrollTo from "./ScrollTo";
import LOGO from "../assets/vero_logo.jpeg";
 
import {Menu, Close} from "@mui/icons-material";

const AppBar = () => {
    const [isTop, setIsTop] = useState(true);
    const [isClosed, setIsClosed] = useState(true);

    //const theme = useTheme();
    //const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let scrollableElement = document.querySelector(".scrollable-content");
    if (!scrollableElement) scrollableElement = window;

    let handleScrollRef = null;

    const handleScroll = () => {
        return debounce(({ target: { scrollTop } }) => {
            let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
            setIsTop(isCurrentTop);
        }, 20);
    };

    handleScrollRef = handleScroll();

    const close = () => {
        setIsClosed(false);
    };

    useEffect(() => {
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScrollRef);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScrollRef);
            }
        };
    }, [scrollableElement, handleScrollRef]);

    return(
        <section
            className={classList({
                header: true,
                "header-fixed": !isTop,
                closed: isClosed,
            })}
            id="home"
        >
            <div className="container header-container">
                <ScrollTo to="home" onScroll={close}>
                    <div className="brand">
                        <img src={LOGO} alt="" />
                    </div>
                </ScrollTo>
                <ul className="navigation">
                    <li>
                        <ScrollTo to="aboutus" onScroll={close}>
                            About Us
                        </ScrollTo>
                    </li>

                    <li>
                        <ScrollTo to="products" onScroll={close}>
                            Products
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="clients" onScroll={close}>
                            Clients
                        </ScrollTo>
                    </li>
                </ul>
                <div className="m-auto" />
                <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Buy"
                    className=""
                >
                    Contact Us
                </Fab>
                <IconButton
                    className="header__toggle"
                    onClick={() => {
                        setIsClosed(!isClosed);
                    }}
                >
                    {isClosed ? <Menu /> : <Close />}
                </IconButton>
            </div>
        </section>
    )
}

export default AppBar;
