import React from "react";

const AboutUs = () => {

    return(
        <div className="section section-service3 bg-light-gray" id="aboutus">
            <div className="container">
                <div className="max-w-400 mb-24 mx-auto text-center">
                    <h2 className="font-normal text-44 mb-4">
                        About Us
                    </h2>
                </div>
                <div className="max-w-900 mb-24 mx-auto text-center">
                    <p>
                        Vero Food Industries Limited is a producer, processor and supplier of quality beverages and food. The company is currently involved in production of two drinking water brands, Vero Natural Mineral Water and Olwendo Natural Mineral Water that come in 500ML, 1.5L AND 18.9L bottles. Olwendo Natural Mineral water is bottled for Majestic Brands Ltd established by the Buganda Kingdom.
                        Our mission is to propel indigenous food and beverage production and processing which will enable adequate and continuous supply of quality food and beverage at an affordable price to everyone.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutUs;
