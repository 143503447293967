import React from "react";
import {Button, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Mail, LocationCityRounded, Twitter, LinkedIn, Facebook} from "@mui/icons-material";

const useStyles = makeStyles(({ palette, ...theme }) => ({
    section: {
        background: "#011C3A",
        color: palette.primary.contrastText,
    },
}));

const Footer = () => {
    const classes = useStyles();
    return(
        <div className={classes.section} id="footer1">
            <div className="container">
                <Grid container>
                    <Grid item lg={6} md={6} sm={12}>
                        <div className="p-8 h-full elevation-z3">
                            <h4 className="text-20 mb-6 relative">About Us</h4>
                            <p className="text-inherit">
                                Vero Food Industries Limited is a producer, processor and supplier of quality beverages and food.
                                The company is currently involved in production of two drinking water brands, Vero Natural Mineral Water
                                and Olwendo Natural Mineral Water that come in 500ML, 1.5L AND 18.9L bottles.
                            </p>
                            <Button variant="contained" color="secondary">
                                Contact Us
                            </Button>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12}>
                        <div className="p-8 h-full elevation-z3">
                            <h4 className="text-20 mb-6 relative">Contact</h4>
                            <div className="px-4 my-8 flex items-center mx--4">
                                <Mail />
                                <div className="pl-4">
                                    <h5 className="m-0 p-0 text-16">Email</h5>
                                    <p className="m-0 p-0 text-inherit">info@verofoods.com</p>
                                </div>
                            </div>
                            <div className="px-4 mt-8 flex items-center mx--4">
                                <LocationCityRounded />
                                <div className="pl-4">
                                    <h5 className="m-0 p-0 text-16">Adress</h5>
                                    <p className="m-0 p-0 text-inherit">
                                        Plot 991 36443, Seeta, Uganda
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12}>
                        <div className="p-8 h-full elevation-z3">
                            <h4 className="text-20 mb-6 relative">Social</h4>
                            <p className="text-inherit">
                                Reach us through our social media accounts below
                            </p>

                            <div className="mt-8">
                                <a href="https://www.linkedin.com/company/vero-food-industries-limited-uganda/?originalSubdomain=ug" className="px-2">
                                    <LinkedIn />
                                </a>
                                <a href="https://twitter.com/verowaterug?referrer=verofoods.com" className="px-2">
                                    <Twitter />
                                </a>
                                <a href="https://www.facebook.com/verowaterug/" className="px-2">
                                    <Facebook />
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Footer;
