import React from "react";
import {
    Route,
    Switch,
    HashRouter as Router
} from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";

import "react-perfect-scrollbar/dist/css/styles.css";
import GlobalCss from "./styles/jss/GlobalCss";
import Home from "./pages/Home";

function App() {
    return(
        <ThemeProvider theme={Theme}>
            <GlobalCss>
                <Scrollbar
                    className="h-full-screen scrollable-content"
                    option={{ suppressScrollX: true }}
                >
                    <Router basename="/">
                        <Switch>
                            <Route path="/" component={Home} exact />
                        </Switch>
                    </Router>
                </Scrollbar>
            </GlobalCss>
        </ThemeProvider>
    );
}

export default App;
