import React, {useEffect} from "react";
import { scrollTo } from "../utils";
import AppBar from "../component/AppBar";
import Header from "../component/Header";
import AboutUs from "../component/AboutUs";
import Products from "../component/Products";
import Clients from "../component/Clients";
import Testimonials from "../component/Testimonials";
import Footer from "../component/Footer";

const Home = () => {
    useEffect(() => {
        scrollTo("root");
    }, []);

    return(
        <div className="landing">
            <AppBar />
            <Header />
            <AboutUs />
            <Products />
            <Clients />
            <Testimonials />
            <Footer />
        </div>
    )
}

export default Home;
