import React from "react";
import Carousel from "./common/Carousel";
import {Avatar, Card, CardContent} from "@mui/material";


const Testimonials = () => {
    const testimonials = [
        {
            user: {
                imageUrl: "",
                name: "Emmanuel Odeke",
                designation: "Operations Manager",
                company: "Orijtech Inc."
            },
        },
        {
            user: {
                imageUrl: "",
                name: "Dr Edward Waduwa",
                designation: "CEO",
                company: "Pearl Rides"
            },
        },
        {
            user: {
                imageUrl: "",
                name: "Brenda Balya",
                designation: "Software Engineer",
                company: "Serena Hotels"
            },
        },
        {
            user: {
                imageUrl: "",
                name: "Edward Katongole",
                designation: "Director",
                company: "CIPLA"
            },
        },
    ];

    return(
        <div className="section" id="testimonials">
            <div className="container text-center">
                <div className="section__header">
                    <h2>What our customers say</h2>
                </div>

                <Carousel carouselId="testimonial-1">
                    {testimonials.map((testimonial, index) => (
                        <Card className="px-6 card w-full h-full" key={index}>
                            <CardContent className="flex-column justify-between min-h-full">
                                <div className="mb-6 mt-4">
                                    <p className="mt-0 mb-16 text-16">
                                        "Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Fugit modi voluptas vero iusto fuga quos totam eius, atis
                                        magnam tempora doloribus ducimus dolorem elit. Fugit modi
                                        voluptas vero iusto fuga quos totam eius Sapiente, quia
                                        tempora."
                                    </p>
                                </div>

                                <div className="flex flex-wrap items-center">
                                    <Avatar
                                        className="w-48 h-48"
                                        src={testimonial.user.imageUrl}
                                    />
                                    <div className="pl-4">
                                        <p className="m-0">
                                            <strong>{testimonial.user.name}</strong>
                                        </p>
                                        <p className="m-0">{testimonial.user.designation}</p>
                                        <p className="m-0">{testimonial.user.company}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </Carousel>
            </div>
        </div>
    )
}

export default Testimonials;
