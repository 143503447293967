
import { variableStyles } from "./_variables";
import { positioningStyles } from "./utilities/_positionings";
import { spacingStyles } from "./utilities/_spacing";
import { colorStyles } from "./utilities/_color";
import { typographyStyles } from "./utilities/_typography";
import { commonStyles } from "./utilities/_common";
import { landingStyles } from "./utilities/_landing";

const GlobalCss = ({ children }) => {
  variableStyles();
  positioningStyles();
  spacingStyles();
  colorStyles();
  typographyStyles();
  commonStyles();
  landingStyles();

  return children;
};

export default GlobalCss;
