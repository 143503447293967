import React from "react";
import {Grid} from "@mui/material";
import PRODUCT_IMAGE from "../assets/bottle.svg";
import WATER from "../assets/vero_water.png";

const PRODUCTS = [
    {
        title: "500ml bottled water",
        image: WATER
    },
    {
        title: "1.5L bottled water",
        image: WATER
    },
    {
        title: "18.9L (Jumbo)",
        image: WATER
    },
    {
        title: "Fresh Juices",
        image: WATER
    },
]

const Products = () => {


    return(
        <section className="section" id="products">
            <div className="container">
                <div className="max-w-400 mb-24 mx-auto text-center">
                    <h2 className="font-normal text-44 mb-4">
                        Our Products
                    </h2>
                    <p className="max-w-400 m-auto mb-8">
                        Our thirst quenching and pure drinking water is filtered by an eclectic process
                    </p>
                </div>
                <Grid container spacing={6} justify="space-between">
                    {PRODUCTS.map((item, ind) => (
                        <Grid key={ind} item sm={4} xs={12}>
                            <div className="text-center max-w-252 mx-auto">
                                <div className="flex justify-center items-center max-w-360 mx-auto">
                                    <img
                                        style={{ width: 60, height: 120}}
                                        src={item.hasOwnProperty("image") ? item.image : PRODUCT_IMAGE}
                                        alt=""
                                    />
                                </div>
                                <h5 className="mt-8 font-medium">{item.title}</h5>
                                <p className="max-w-400">
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                    diam nonumy eirmod tempor invidunt ut labore
                                </p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </section>
    )
}

export default Products;
