import React from "react";

import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material";
import clsx from "clsx";
import ILLUSTRATION from "../assets/industry.svg";

const useStyles = makeStyles(({palette, ...theme}) => ({
    headerWrapper: {
        padding: "5rem 0px !important",
        overflow: "visible !important",

        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0 !important",
            textAlign: "center",
            "& .list": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
        },
    },
    title: {
        textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
    },
}))

const Header = () => {
    const classes = useStyles();

    return(
        <section className="section" id="header">
            <div className={classes.headerWrapper}>
                <div className="container">
                    <Grid container spacing={3} justify="center" alignItems="center">
                        <Grid item md={6}>
                            <h1 className={clsx("text-48 mb-6 text-secondary", classes.title)}>
                                Vero Foods
                            </h1>
                            <h1 className={clsx("text-48 mb-6 text-secondary", classes.title)}>
                                Industries
                            </h1>
                            <div className="text-22 mb-10">
                                We are a premier food & beverages enterprise sustainably servicing Uganda,
                                the Pearl of Africa with assortments of nutritious beverages & foods.
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <div className="flex justify-center items-center max-w-360 mx-auto">
                                <img
                                    className="w-full"
                                    src={ILLUSTRATION}
                                    alt=""
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>
    )
}

export default Header;
