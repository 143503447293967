import React from "react";
import centenaryBank from "../assets/centenarybank.jpeg";
import f4GCapital from "../assets/4g-capital.png";
import ciplaqci from "../assets/cipla.svg";
import stanbic from "../assets/stanbic.jpg";
import kampalaSerena from "../assets/kampala-serena.svg";
import eaRoofing from "../assets/eaRoofing.jpg";
import moe from "../assets/moe.jpg";
import agromax from "../assets/agromax.png";
import kisubiHospital from "../assets/kisubi-hospital.png";
import lubagaHospital from "../assets/lubaga-hospital.png";
import nsambyaTrainingHospital from "../assets/nsambya-training-hospital.svg";

const Clients = () => {

    return(
        <section className="section section-bg-light-primary" id="clients">
            <div className="container text-center">
                <h1 className="font-normal text-primary text-44 mb-4">
                    Some of our esteemed customers
                </h1>
                <p className="max-w-400 m-auto mb-8">
                    We are trusted by a wide range of customers who use our products and
                    services.
                </p>
            </div>
            <div className="flex flex-wrap justify-center items-center">
                <img
                    src={kampalaSerena}
                    width="200vh"
                    href="https://www.serenahotels.com/kampala"
                    alt=""
                    className="full mx-8 mt-8"
                />
                <img
                    src={centenaryBank}
                    alt=""
                    width="280vh"
                    className="full mx-8 mt-8"
                />
                <img
                    src={f4GCapital}
                    alt=""
                    width="200vh"
                    className="full mx-8 mt-8"
                />
                <img
                    src={ciplaqci}
                    width="200vh"
                    alt="CIPLA-QCI"
                    className="full mx-8 mt-8"
                />
                <img
                    src={stanbic}
                    width="140vh"
                    alt=""
                    className="full mx-8 mt-8"
                />
                
                <img
                    src={eaRoofing}
                    width="160vh"
                    alt=""
                    className="full mx-8 mt-8"
                />
                <img
                    src={nsambyaTrainingHospital}
                    width="220vh"
                    alt="Lubaga Hospital"
                    className="full mx-8 mt-8"
                />
                <img
                    src={moe}
                    width="160vh"
                    alt=""
                    className="full mx-8 mt-8"
                />
                <img
                    src={agromax}
                    width="250vh"
                    alt="AgroMax"
                    className="full mx-8 mt-8"
                />
                <img
                    src={kisubiHospital}
                    width="250vh"
                    alt="Kisubi Hospital"
                    className="full mx-8 mt-8"
                />
                <img
                    src={lubagaHospital}
                    width="280vh"
                    alt="Lubaga Hospital"
                    className="full mx-8 mt-8"
                />
            </div>
        </section>
    )
}

export default Clients;
